<template>
	<div class="grid">
		<div class="col-12 md:col-12">
		<div class="card">
			<DataTable :value="customer1" :paginator="true" class="p-datatable-gridlines" :rows="15" dataKey="id" :rowHover="true" 
						v-model:filters="filters1" filterDisplay="menu" :loading="loading1" :filters="filters1" responsiveLayout="scroll"
						:globalFilterFields="['name','country.name','representative.name','balance','status']" >
				
				<template #header>
					<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" class="mb-3" />						
					<div class="grid grid-nogutter">
						<div class="col-6 text-left">
							<Button label="返回" icon="pi pi-chevron-left" class="p-button-primary mr-2" @click="goBack"/>							
							<Button label="新增区域" icon="pi pi-plus" class="p-button-secondary mr-2" @click="openAddField" />							
						</div>
						<div class="col-6 text-right">
							<span class="p-input-icon-left mb-2">
								<i class="pi pi-search" />
								<InputText v-model="filters1['global'].value" placeholder="关键词搜索" style="width: 100%"/>
							</span>
						</div>
						<Dialog header="新增区域" v-model:visible="displayAddField" :style="{width: '25vw'}" :modal="true">
							<div class="field grid">
								<label for="username1" class="col-fixed w-9rem">所属企业</label>
								<div class="col">
									<InputText id="username1" v-model="username" :disabled="true" :required="true" class="mr-2"></InputText>									
								</div>
							</div>
							<div class="field grid">
								<label for="email" class="col-fixed w-9rem">区域名称</label>
								<div class="col">
									<InputText id="email" v-model="email" :required="true" class="p-invalid mr-2"></InputText>
									<InlineMessage>无效账号</InlineMessage>
								</div>
							</div>
							<template #footer>
								<Button label="取消" icon="pi pi-times" @click="closeModal" class="p-button-text"/>
								<Button label="确定" icon="pi pi-check" @click="closeModal" autofocus />
							</template>
						</Dialog>
					</div>
				</template>
				<template #empty>
					No customers found.
				</template>
				<template #loading>
					Loading customers data. Please wait.
				</template>
				<Column field="name" header="ID" style="min-width:5rem">
					<template #body="{data}">
						{{data.id}}
					</template>                        
				</Column>
				<Column field="name" header="区域名称" style="min-width:15rem">
					<template #body="{data}">
						{{data.country.name}}
					</template>                        
				</Column>
				<Column field="name" header="所属场地" style="min-width:10rem">
					<template #body="{data}">
						{{data.country.name}}
					</template>                        
				</Column>								
				<Column header="设置" style="min-width:5rem">                    
					<template #body="{data}">   
						{{ data.balance }}
					</template>
				</Column>
				<Column header="操作" style="min-width:5rem">
					<template #body="{data}">     
						<span class="p-input-icon-left">
							<Button label="编辑" class="p-button-success p-button-text mr-1"  @click="editCompany(data.id)" />
							<Button label="删除" class="p-button-danger p-button-text mr-1" @click="editCompany(data.id)" />
						</span>
					</template>
				</Column>
			</DataTable>
		</div>
		</div>

	</div>
</template>

<script>
	import {FilterMatchMode,FilterOperator} from 'primevue/api';
	import CustomerService from "../../service/CustomerService";
	import ProductService from '../../service/ProductService';
	export default {
		data() {
			return {
				customer1: null,
				customer2: null,
				customer3: null,
				filters1: null,
				displayAddField: false,
				displayAddGroup:false,
				filters2: {},
				loading1: true,
				loading2: true,
				idFrozen: false,
				products: null,
				expandedRows: [],
				statuses: [
					'unqualified', 'qualified', 'new', 'negotiation', 'renewal', 'proposal'
				],
				representatives: [
					{name: "Amy Elsner", image: 'amyelsner.png'},
					{name: "Anna Fali", image: 'annafali.png'},
					{name: "Asiya Javayant", image: 'asiyajavayant.png'},
					{name: "Bernardo Dominic", image: 'bernardodominic.png'},
					{name: "Elwin Sharvill", image: 'elwinsharvill.png'},
					{name: "Ioni Bowcher", image: 'ionibowcher.png'},
					{name: "Ivan Magalhaes",image: 'ivanmagalhaes.png'},
					{name: "Onyama Limba", image: 'onyamalimba.png'},
					{name: "Stephen Shaw", image: 'stephenshaw.png'},
					{name: "XuXue Feng", image: 'xuxuefeng.png'}
				],
				breadcrumbHome: {icon: 'pi pi-home', label:' James Butt', to: '#'},
				breadcrumbItems: [],
			}
		},
		customerService: null,
		productService: null,
		created() {
			this.customerService = new CustomerService();
			this.productService = new ProductService();
			this.initFilters1();
		},
		mounted() {
			this.productService.getProductsWithOrdersSmall().then(data => this.products = data);
			this.customerService.getCustomersLarge().then(data => {
				this.customer1 = data; 
				this.loading1 = false;
				this.customer1.forEach(customer => customer.date = new Date(customer.date));
			});
			this.customerService.getCustomersLarge().then(data => this.customer2 = data);
			this.customerService.getCustomersMedium().then(data => this.customer3 = data);
			this.loading2 = false;

			if(this.$route.params.grp_name != '')
			{
				console.log('grp_name:' + this.$route.params.grp_name)
				this.breadcrumbItems.push({
					label: this.$route.params.grp_name
				})
			}

			if(this.$route.params.fid_name != '')
			{
				console.log('fid_name:' + this.$route.params.fid_name)
				this.breadcrumbItems.push({
					label: this.$route.params.fid_name
				})
			}

			if(this.$route.params.comp_name != '')
			{
				this.breadcrumbHome.label = this.$route.params.comp_name;
			}
		},
		methods: {
			initFilters1() {
				this.filters1 = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
					'country.name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
					'representative': {value: null, matchMode: FilterMatchMode.IN},
					'date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
					'balance': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
					'status': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
					'activity': {value: null, matchMode: FilterMatchMode.BETWEEN},
					'verified': {value: null, matchMode: FilterMatchMode.EQUALS}
				}
			},			
			goBack() {
				this.$router.push({ 
					name: 'groupfieldconfig',
					params:{
						comp_id: this.$route.params.comp_id,
						grp_id: this.$route.params.grp_id,
						comp_name: this.$route.params.comp_name,
						grp_name: this.$route.params.grp_name,
					}
				});				
			},
			openAddField() {
				this.displayAddField = true;
			},
			openAddGroup(){
				this.displayAddGroup = true;
			},
			onRowSelect(event) {
				this.$toast.add({severity: 'info', summary: 'Product Selected', detail: 'Name: ' + event.data.name, life: 3000});
			},
			onRowUnselect(event) {
				this.$toast.add({severity: 'warn', summary: 'Product Unselected', detail: 'Name: ' + event.data.name, life: 3000});
			},
			clearFilter1() {
				this.initFilters1();
			},
			expandAll() {
				this.expandedRows = this.products.filter(p => p.id);
			},
			collapseAll() {
				this.expandedRows = null;
			},
			badgeColor(value) {
				return value>40?'success':'warning';
			},
			removeCompany(id) {
				console.print('remove:' + id);
			},			
			editCompany(id) {
				console.print('edit ' + id);
			},
			formatCurrency(value) {
				return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
			},
			formatDate(value) {
				return value.toLocaleDateString('en-US', {
					day: '2-digit',
					month: '2-digit',
					year: 'numeric',
				});
			},
			calculateCustomerTotal(name) {
				let total = 0;
				if (this.customer3) {
					for (let customer of this.customer3) {
						if (customer.representative.name === name) {
							total++;
						}
					}
				}

				return total;
			}
		}
	}
</script>